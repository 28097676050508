import React from 'react'

function DebounceTimer({timer}) {
    const ref = React.useRef()
    const [time, setTime] = React.useState(timer)
    let t = undefined
    React.useEffect(() => {
        setTime(timer); clearTimeout(t)
    }, [timer])
    React.useEffect(() =>{
        var circle = ref.current
        var radius = circle.r.baseVal.value;
        var circumference = radius * 2 * Math.PI;
        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = `${circumference}`;
        function setProgress(percent) {
            const offset = circumference - percent / 100 * circumference;
            circle.style.strokeDashoffset = offset;
        }
        setProgress(time)
    }, [ref, time])
    if(time > 0){
        t = setTimeout(()=>{
            setTime(time-1)
        }, timer/100)
    }
    return (
        <svg
        
        class="progress-ring"
        width="60"
        height="60">
        <circle
        ref={ref}
            class={"progress-ring__circle " + time}
            stroke="red"
            stroke-width="2"
            fill="transparent"
            r="8"
            cx="30"
            cy="30"/>
        </svg>
    )
}

export default DebounceTimer